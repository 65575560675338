<template>
  <ion-row class="grey-container">
    <ion-list>
      <slot />
      <ion-item
        v-for="(item, index) in content"
        :key="index"
        class="items"
        :router-link="item.link"
        button="true"
      >
        <ion-icon
          v-if="item.icon"
          :icon="item.icon"
          class="list-icon"
        />
        <ion-label>{{ item.text }}</ion-label>
        <ion-icon :icon="chevronForwardSharp" class="icon-light" />
      </ion-item>
    </ion-list>
  </ion-row>
</template>

<script lang="ts">
import { IonIcon, IonRow, IonList, IonItem } from '@ionic/vue'

export default {
  name: 'Profile',
  components: {
    IonRow,
    IonList,
    IonItem,
    IonIcon,
  },
  props: {
    content: Array,
  },
}
</script>

<style scoped>
.grey-container,
.white-container {
  border-radius: 30px;
  text-align: left;
}

ion-list {
  background: none;
  width: 100%;
  padding-top: 0;
}

ion-item {
  display: block;
  --border-color: var(--ion-color-medium);
}

ion-item::part(native) {
  padding-left: 0;
  --inner-padding-end: 0;
}

ion-list .items:last-of-type {
  --border-color: transparent;
  margin-bottom: -10px;
}

.icon-light {
  color: var(--ion-color-medium);
}

.list-icon {
  color: var(--ion-color-primary);
  margin-right: 10px;
}
</style>
