<template>
  <ion-page>
    <ion-content :fullscreen="true" style="text-align: center;">
      <div class="white-container">
        <back-link text="MENU" />
        <grey-box-list :content="account">
          <div class="dark-box">
            {{ $t('Your account') }}
          </div>
        </grey-box-list>
        <grey-box-list :content="preferences">
          <div class="dark-box">
            {{ $t('Preferences') }}
          </div>
        </grey-box-list>
        <grey-box-list :content="other">
          <div class="dark-box">
            {{ $t('Other settings') }}
          </div>
        </grey-box-list>
      </div>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import BackLink from '@/components/settings/BackLink.vue'
import GreyBoxList from '@/components/settings/GreyBoxList.vue'

import { IonContent, IonPage } from '@ionic/vue'

export default {
  name: 'Profile',
  components: {
    BackLink,
    GreyBoxList,
    IonContent,
    IonPage,
  },
  data() {
    return {
      account: [
        { text: 'Current Membership' },
        { text: 'Services and Applications' },
        { text: 'Change Email' },
        { text: 'Change Password' },
        { text: 'Help' },
      ],
      preferences: [
        { text: 'Units of Measurements' },
        { text: 'Notifications' },
        { text: 'Data Permission' },
        { text: 'Feed Ordering' },
      ],
      other: [{ text: 'Siri and Shortcuts' }, { text: 'Apple Watch' }, { text: 'Beacon' }],
    }
  },
}
</script>

<style scoped>
.grey-container,
.white-container {
  border-radius: 30px;
  text-align: left;
}

.dark-box {
  background-color: var(--ion-color-medium);
  color: var(--ion-color-secondary);
  padding: 10px 15px;
  border-radius: 20px;
}
</style>
