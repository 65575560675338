
import { IonIcon, IonRow, IonList, IonItem } from '@ionic/vue'

export default {
  name: 'Profile',
  components: {
    IonRow,
    IonList,
    IonItem,
    IonIcon,
  },
  props: {
    content: Array,
  },
}
