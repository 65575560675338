
import BackLink from '@/components/settings/BackLink.vue'
import GreyBoxList from '@/components/settings/GreyBoxList.vue'

import { IonContent, IonPage } from '@ionic/vue'

export default {
  name: 'Profile',
  components: {
    BackLink,
    GreyBoxList,
    IonContent,
    IonPage,
  },
  data() {
    return {
      account: [
        { text: 'Current Membership' },
        { text: 'Services and Applications' },
        { text: 'Change Email' },
        { text: 'Change Password' },
        { text: 'Help' },
      ],
      preferences: [
        { text: 'Units of Measurements' },
        { text: 'Notifications' },
        { text: 'Data Permission' },
        { text: 'Feed Ordering' },
      ],
      other: [{ text: 'Siri and Shortcuts' }, { text: 'Apple Watch' }, { text: 'Beacon' }],
    }
  },
}
